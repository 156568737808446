<template>
    <ParticipanteBayerList />
</template>

<script>
    import ParticipanteBayerList from '../../components/cadastro/ParticipanteBayerList.vue';

    export default {
        name: 'Participante Bayer Page',
        components: { ParticipanteBayerList }
    }

</script>
